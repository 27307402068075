@use '@angular/material' as mat;

$theme-primary-hue: 700;
$theme-accent-hue: 500;
$theme-warn-hue: 500;

$theme-primary: mat.define-palette(mat.$yellow-palette, $theme-primary-hue);
$theme-accent: mat.define-palette(mat.$teal-palette, $theme-accent-hue);
$theme-warn: mat.define-palette(mat.$red-palette, $theme-warn-hue);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);

@include mat.all-component-themes($theme);

.fury-default {
  @import '../@fury/styles/themes/default';

  background: $theme-background;
  color: $theme-text;

  @import './layout/config-panel/config-panel.component.theme';
}

.fury-light {
  @import '../@fury/styles/themes/light';

  background: $theme-background;
  color: $theme-text;

  @import './layout/config-panel/config-panel.component.theme';
}

.fury-dark {
  @include mat.all-component-colors($dark-theme);
  $theme: $dark-theme;

  @import '../@fury/styles/themes/dark';

  background: $theme-background;
  color: $theme-text;

  @import './layout/config-panel/config-panel.component.theme';
}

.fury-flat {
  @import '../@fury/styles/themes/flat';

  background: $theme-background;
  color: $theme-text;

  @import './layout/config-panel/config-panel.component.theme';
}
