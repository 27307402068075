@use '@angular/material' as mat;

fury-list {
  @include mat.elevation($elevation);
  border-radius: $border-radius;
  overflow: auto;
  display: flex;

  .fury-list-table {
    flex: 1 1 auto;
  }

  .fury-list-header {
    background: $theme-card;
    border-bottom: 1px solid $theme-divider;

    .fury-filter-input {
      background: $theme-card;
      color: currentColor;

      ::placeholder {
        color: currentColor;
      }
    }

    .fury-filter-input-icon,
    .fury-column-filter {
      color: $theme-icon;
    }

    .fury-list-name {
      border-right: 1px solid $theme-divider;
    }
  }

  .mat-header-row,
  .paginator {
    background: $theme-app-bar;
  }

  .mat-row:hover {
    background: $theme-background-hover;
  }

  .mat-cell.actions-cell,
  .mat-header-cell.actions-cell {
    button {
      color: $theme-icon;
    }
  }

  .mat-cell.image-cell,
  .mat-header-cell.image-cell {
    img {
      background: $theme-app-bar;
    }
  }
}

.fury-list-table {
  @include mat.elevation($elevation);
  border-radius: $border-radius;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .fury-list-header {
    padding-left: $spacing;
    padding-right: $spacing;
    height: $card-header-height;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    @include media-xs {
      height: auto;
      padding-top: $spacing;
      padding-bottom: $spacing;
    }

    .fury-list-name {
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
      padding-right: $spacing;

      @include media-xs {
        border-right: none;
      }
    }

    .fury-filter-input {
      font-size: 16px;
      line-height: 1.125;
      border: none;
      height: 32px;

      &:focus {
        outline: none;
      }
    }

    .fury-filter-input-icon {
      width: 1.2em;
      vertical-align: bottom;
      height: 22px;
      font-size: 22px;
      margin-right: $padding-xs;
    }
  }

  .mat-header-row .mat-header-cell .mat-sort-header-button {
    text-transform: uppercase;
  }

  .mat-cell.image-cell,
  .mat-header-cell.image-cell {
    flex: 0;
    width: 35px + $spacing;

    img {
      border-radius: 50%;
      height: 35px;
      width: 35px;
      margin-right: $spacing;
      vertical-align: middle;
    }
  }

  .mat-cell,
  .mat-header-cell {
    white-space: nowrap;
    padding-left: $padding-sm;
    padding-right: $padding-sm;
  }

  .mat-cell.actions-cell,
  .mat-header-cell.actions-cell {
    flex: 0;
    width: 40px;
    overflow: visible;
  }

  .paginator {
    display: block;
  }
}

.clickable {
  cursor: pointer;
}

.return {
  background-color: #757575 !important;
  color: white;
}

.save {
  background-color: #388e3d !important;
  color: white;
}

.input-position {
  margin: auto !important;
}

.selected-row {
  background-color: #c8e6c9;
}

::ng-deep .mat-tooltip {
  white-space: pre-line !important
}

.paginator {
  border-bottom: 1px solid;
}

.column-spacing th, .column-spacing td {
  padding-left: 20px;
  padding-right: 20px;
}
