@use '@angular/material' as mat;
@use 'sass:math';

.cal-month-view {
  background-color: $theme-background;

  .cal-days {
    border-color: $theme-divider;

    .cal-cell-row {
      border-color: $theme-divider;
    }
  }

  .cal-header .cal-cell {
    padding-top: math.div($spacing, 2);
    padding-bottom: math.div($spacing, 2);
  }

  .cal-cell-row {
    &:hover {
      background: $theme-background-hover;
    }

    .cal-cell {
      &:hover,
      &.cal-open {
        background: $theme-background-hover;
      }
    }
  }

  .cal-day-cell {
    &.cal-today {
      background: $theme-status-bar;
    }

    &:not(:last-child) {
      border-color: $theme-divider;
    }
  }

  .cal-open-day-events {
    border-bottom: 1px solid $theme-divider;

    > div {
      background: $theme-card;
      color: $theme-text;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      padding-left: $spacing;
      padding-right: $spacing;

      @include mat.elevation(2);

      & + div {
        margin-top: math.div($spacing, 2);
      }

      mwl-calendar-event-title {
        flex: 1;
        display: flex;
        flex-direction: row;

        .cal-event-title {
          color: $theme-text;
          flex: 1;
          padding: math.div($spacing, 2);
        }
      }

      .cal-event-action {
        color: $theme-icon;

        & + .cal-event-action {
          margin-left: math.div($spacing, 2);
        }
      }
    }
  }
}

.cal-week-view,
.cal-day-view {
  .cal-header {
    &.cal-weekend span {
      color: $theme-secondary-text;
    }

    &.cal-today {
      background: $theme-status-bar;
    }
  }

  .cal-day-headers .cal-header:hover,
  .cal-day-headers .cal-drag-over {
    background-color: $theme-background-hover;
  }

  .cal-hour {
    background: $theme-card;
  }

  .cal-hour-odd {
    background: $theme-app-bar;
  }

  .cal-hour-segment {
    &:hover {
      background: $theme-background-hover;
    }
  }

  .cal-time-events {
    .cal-day-columns {
      .cal-hour-segment {
        &:hover {
          background: $theme-background-hover;
        }
      }
    }
  }

  .cal-event {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    mwl-calendar-event-actions {
      order: 2;
    }

    mwl-calendar-event-title {
      order: 1;
      flex: 1;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .cal-event-title {
        outline: none;
      }
    }

    .cal-event-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      align-content: center;

      .cal-event-action {
        color: $theme-icon;
        height: auto;

        .icon {
          font-size: 18px;
          padding: math.div($spacing, 4) math.div($spacing, 6);
        }
      }
    }
  }
}

.cal-event-title {
  text-decoration: none;
  color: $theme-text;

  font: var(--font-body-1);
}

i.icon {
  //noinspection CssNoGenericFontName
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;
}
