fury-breadcrumbs {
  .crumb,
  .link {
    color: $theme-secondary-text;

    &:hover {
      color: $theme-text;
    }
  }

  .crumb.current,
  .crumb.current .link {
    color: $theme-text;
  }
}
